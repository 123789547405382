import React from "react"
import { Container } from "react-bootstrap"
import { Section } from "../components/Core"

const NotFoundPage = () => {
  return (
    <>
      <Section bg="secondary" py="250px!important">
        <Container
          className="text-center position-relative"
          css={`
            z-index: 10;
          `}
        >
          <h1 className="text-white">404 Error!</h1>
          <p className="text-white">Page not found!</p>
        </Container>
      </Section>
    </>
  )
}

export default NotFoundPage
